<script setup lang="ts">
import { defineProps, onMounted } from 'vue'
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

const { t } = useI18n()
const store = useStore()
const route = useRoute()

const { items } = defineProps<{
  items: {
    name: string
    route: {
      name: string
      params?: Record<string, string>
    }
    event?: string
    icon: string
    display?: boolean
  }[]
}>()

const eventHandler = (event: string) => {
  if (event === 'startTutorial') {
    if (store.getters['interactabletutorial/started']) {
      store.dispatch('interactabletutorial/stop')
    }
    store.dispatch('interactabletutorial/start')
  }
}
</script>

<template>
  <template v-for="item in items" :key="item.name">
    <div v-if="item.route">
      <router-link custom :to="item.route" v-slot="{ isActive, href, navigate }" v-if="item.display === undefined || item.display !== false">
      <slot :item="item" :isActive="isActive" :href="href" :navigate="navigate" />
    </router-link>
    </div>
    <div v-else-if="item.event" @click="eventHandler(item.event)" v-if="item.display === undefined || item.display !== false">
      <slot :item="item" />
    </div>
  </template>
</template>
