<script setup lang="ts">
import { ref, Ref} from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
  show: boolean
}>()

const { t } = useI18n()
const publicPath: Ref<string> = ref<string>(window.location.origin)

</script>

<template>

    <div :class="props.show ? 'block' : 'hidden'" role="button" class="absolute inset-0 justify-center flex items-center">
      <div id="locationsandpoints" class="relative grid grid-cols-2 gap-4 items-center justify-center group backdrop-blur-sm">
        <img :src="publicPath + '/icons/sensors/water_level.svg'" class="sensor-icon w-[35px] h-[35px]">
        <img :src="publicPath + '/icons/sensors/monitoring_well.svg'" class="sensor-icon w-[35px] h-[35px] ">
        <img :src="publicPath + '/icons/sensors/inclino.svg'" class="sensor-icon w-[35px] h-[35px] ">
        <img :src="publicPath + '/icons/sensors/settlement_marker.svg'" class="sensor-icon w-[35px] h-[35px] ">
      </div>
    </div>

</template>
