/* v8 ignore next 1 */
<script setup lang="ts">
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { onMounted, watch, ref } from 'vue';

const store = useStore()
const { t } = useI18n()

const props = defineProps<{
  show: boolean
}>()

const publicPath = window.location.origin
const topItems = [
  { name: t('Map'), route: { name: 'control-panel.map' }, icon: 'far fa-map-location-dot' },
  { name: t('Project', 2), route: { name: 'control-panel.projects' }, icon: 'fas fa-list' }
]

const bottomItems = [
  { name: t('Help'), event: 'startTutorial', disableSmallScreen: true, icon: 'far fa-question-circle' },
  { name: t('Manual', 2), route: { name: 'manuals' }, icon: 'far fa-book' },
  { name: t('Setting', 2), route: { name: 'settings' }, icon: 'far fa-cog' },
  { name: t('Sign out'), route: { name: 'auth.signOut' }, icon: 'far fa-sign-out-alt' },
]
const isTestEnvironment = import.meta.env.VITE_TEST_ENVIRONMENT === 'true'
</script>

<template>
  <div :class="props.show ? 'block' : 'hidden'" class="fixed group transition-all duration-300 ease-in-out h-full z-[54]">
    <div :class="[
      'hidden md:fixed md:inset-y-0 md:flex md:flex-col transition-all duration-300 ease-in-out p-3 w-56'
    ]">

      <div id="sidebar" :class="[
        'flex min-h-0 flex-1 flex-col bg-gradient-to-r backdrop-blur rounded-lg shadow-bordered transition-all from-white to-white/70'
      ]">
        <div class="relative flex flex-1 flex-col overflow-y-auto pb-4 overflow-hidden rounded-t-lg">
          <div class="flex justify-center items-center h-12 bg-white px-3 gap-x-3.5 text-center border-b border-gray-200">
            <RouterLink :to="{ name: 'map.projects' }" class="bg-right bg-[length:198px_27px] bg-no-repeat h-7 w-7 hidden" :style="`background-image:url(${publicPath}/img/logo-white.svg)`"/>
            <RouterLink :to="{ name: 'map.projects' }" class="bg-right bg-[length:198px_27px] bg-no-repeat h-7 w-7 inline-block" :style="`background-image:url(${publicPath}/img/logo-dark.svg)`"/>
          </div>
          <div class="mt-2">
            <nav class="flex-1 space-y-1 px-2">
              <div v-for="item in topItems" :key="item.name">
                <div v-if="item.route">
                  <router-link :to="item.route" class="text-gray-800 hover:bg-gray-100 border-white hover:border-gray-200 hover:text-gray-800 flex items-center justify-start gap-x-2 px-2 py-2 text-sm rounded-md align-middle border">
                    <i :class="`${item.icon} flex-shrink-0 text-lg w-6 align-middle text-center`"></i>
                    <p class="break-words first-letter:capitalize align-middle transition-opacity ease-in-out opacity-100">{{ item.name }}</p>
                  </router-link>
                </div>
              </div>
            </nav>
          </div>
        </div>

        <RouterLink
          v-if="isTestEnvironment"
          :to="{ name: 'settings.system' }"
          class="bg-softred-500 hover:bg-softred-600 transition-colors border-gray-100 text-xs py-1.5 text-softred-50 text-center font-bold uppercase">
          <span v-t="'Test environment'" />
        </RouterLink>

        <div
          :class="[
            'border-gray-100 py-2',
            { 'border-t': !isTestEnvironment }
          ]">
          <div v-for="item in bottomItems" :key="item.name">
            <div v-if="item.route">
              <router-link :to="item.route" class="text-gray-800 hover:bg-gray-100 border-white hover:border-gray-200 hover:text-gray-800 flex items-center justify-start gap-x-2 px-2 py-2 text-sm rounded-md align-middle border">
                <i :class="`${item.icon} flex-shrink-0 text-lg w-6 align-middle text-center`"></i>
                <p class="break-words first-letter:capitalize align-middle transition-opacity ease-in-out opacity-100">{{ item.name }}</p>
              </router-link>
            </div>
            <div v-else-if="item.event">
              <div class="text-gray-800 hover:bg-gray-100 border-white hover:border-gray-200 hover:text-gray-800 flex items-center justify-start gap-x-2 px-2 py-2 text-sm rounded-md align-middle border cursor-pointer">
                <i :class="`${item.icon} flex-shrink-0 text-lg w-6 align-middle text-center`"></i>
                <p class="break-words first-letter:capitalize align-middle transition-opacity ease-in-out opacity-100">{{ item.name }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
