<script setup lang="ts">
import { Ref, ref, onBeforeMount, computed, inject, ComputedRef, reactive, onBeforeUnmount } from 'vue'
import { Dialog, DialogTitle, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { RouterView } from 'vue-router'
import { AccountInfo, AuthenticationResult, BrowserAuthError, InteractionRequiredAuthError, PublicClientApplication, RedirectRequest, SilentRequest } from '@azure/msal-browser'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import axios, { AxiosStatic } from 'axios'
import Sidebar from '@/components/navigation/Sidebar.vue'
import SidebarNav from '@/components/navigation/sidebar/Nav.vue'
import SidebarItems from '@/components/navigation/sidebar/Items.vue'
import SidebarItem from '@/components/navigation/sidebar/Item.vue'
import { onUnmounted, onMounted, nextTick } from 'vue'
import InteractableTutorial from '@/components/InteractableTutorial.vue'
import interact from 'interactjs';
import { useRouter } from 'vue-router';
import * as Sentry from "@sentry/browser"

const store = useStore()
const msal: PublicClientApplication | undefined = inject('msal')
const router = useRouter();
const tokenRenewalInterval: Ref<NodeJS.Timer | null> = ref(null)
const api: AxiosStatic = inject('axios', axios)
const { t, locale } = useI18n()
const publicPath = window.location.origin
const sidebarOpen: Ref<boolean> = ref<boolean>(false)
const sidebarExpanded: Ref<boolean> = ref<boolean>(false)
const topNavigation = [
  // { name: t('Company', 2), route: { name: 'settings.companies.list' }, icon: 'far fa-buildings' },
  { name: t('Project', 2), route: { name: 'control-panel.projects.list' }, icon: 'far fa-list' },
  // { name: t('Map'), route: { name: 'map.projects' }, icon: 'far fa-map' },
]

const essentialsFetched: ComputedRef<boolean> = computed(() => {
  return !!store.getters['auth/JWTToken']
})

async function checkAuthentication() {
  if (!msal) {
    console.error('MSAL instance is not available');
    // loading.value = false;
    return;
  }

  try {
    console.info('Initializing MSAL');
    await msal.initialize();

    console.info('Handling redirect promise');
    const response = await msal.handleRedirectPromise();

    if (response && response.account) {
      console.info('Redirect response received:', response);
      msal.setActiveAccount(response.account);

      // Store active account ID
      sessionStorage.setItem('activeAccountId', response.account.homeAccountId);

      await handleAuthentication(response);
    } else {
      console.info('No redirect response, checking accounts');
      const currentAccounts = msal.getAllAccounts();
      console.info('Accounts found:', currentAccounts);

      if (currentAccounts.length === 0) {
        console.info('No accounts found, redirecting to login page');
        await router.replace({ name: 'Login' });
      } else {
        let activeAccount = msal.getActiveAccount();

        if (!activeAccount) {
          // Try to retrieve activeAccountId from storage
          const activeAccountId = sessionStorage.getItem('activeAccountId');
          if (activeAccountId) {
            activeAccount = currentAccounts.find(
              acc => acc.homeAccountId === activeAccountId
            );
            if (activeAccount) {
              console.info('Active account set from sessionStorage:', activeAccount.username);
              msal.setActiveAccount(activeAccount);
            } else {
              console.warn('Active account ID from sessionStorage not found in current accounts');
            }
          } else {
            console.warn('No active account ID found in sessionStorage');
          }
        }

        if (!activeAccount) {
          // If still no active account, pick the first account
          activeAccount = currentAccounts[0];
          msal.setActiveAccount(activeAccount);
          console.info('Active account set to first account in list:', activeAccount.username);
        }

        await authenticateAccount(activeAccount);
      }
    }
  } catch (error) {
    console.error('Error during authentication check:', error);
    if (
      error instanceof BrowserAuthError &&
      error.errorCode === 'uninitialized_public_client_application'
    ) {
      console.error('MSAL is uninitialized, initializing now');
      try {
        await msal.initialize();
        await checkAuthentication(); // Retry after initialization
      } catch (initError) {
        console.error('MSAL initialization failed:', initError);
        await router.replace({ name: 'Login' });
      }
    } else {
      await router.replace({ name: 'Login' });
    }
  } finally {
    // loading.value = false;
  }
}

async function authenticateAccount(account: AccountInfo) {
  if (!msal) {
    console.error('MSAL instance is not available');
    return;
  }

  // The active account should already be set in checkAuthentication
  console.info('Authenticating account:', account.username);

  try {
    const response = await msal.acquireTokenSilent({
      scopes: [`${import.meta.env.VITE_MSAL_CLIENT_ID}/.default`],
      account: account,
    });
    console.info('Token acquired silently for account:', account.username);
    await handleAuthentication(response);
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      console.warn('Silent token acquisition failed, requiring interaction:', error);
      try {
        // Save the selected account's homeAccountId before redirect
        sessionStorage.setItem('activeAccountId', account.homeAccountId);

        await msal.acquireTokenRedirect({
          scopes: [`${import.meta.env.VITE_MSAL_CLIENT_ID}/.default`],
          prompt: 'select_account',
          account: account,
        });
      } catch (redirectError) {
        console.error('Token acquisition via redirect failed:', redirectError);
        await router.replace({ name: 'Login' });
      }
    } else {
      console.error('Token acquisition error:', error);
      await router.replace({ name: 'Login' });
    }
  }
}

async function handleAuthentication(response: AuthenticationResult) {
  if (response && response.account) {
    console.info('Authentication successful for account:', response.account.username);
    msal.setActiveAccount(response.account);

    // Retrieve access token
    const accessToken = response.accessToken;

    // Proceed with authenticated actions
    await authenticated(accessToken, response.account);
  } else {
    console.error('Authentication failed: No response or account information.');
    await router.replace({ name: 'Login' });
  }
}

async function authenticated(accessToken: string, account: AccountInfo) {
  if (!msal) {
    console.error('MSAL instance is not available');
    return;
  }

  if (!account) {
    console.error('No account provided after authentication');
    return;
  }

  // Optional: Set active account again
  msal.setActiveAccount(account);

  // Set API headers
  api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

  try {
    // Fetch user data from API
    console.info('Fetching user data from API');
    const userResponse = await api.get(`GetUser/${account.localAccountId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    });

    const userData = userResponse.data;
    console.info('User data retrieved:', userData);

    // Update store with user data
    store.commit('user/setId', userData.id ?? '');
    store.commit('user/setCompanyAdmin', userData.isCompanyAdmin ?? false);
    store.commit('user/setApplicationAdmin', userData.isApplicationAdmin ?? false);
    store.commit('user/setSettings', userData.settings ?? {});
    store.commit('user/setBelongsToCompany', userData.belongsToCompany);
    store.commit('user/setProjectAccessIds', userData.userProjects ?? []);
    store.commit('user/setName', userData.name ?? '');
    store.dispatch('auth/setJWTToken', accessToken);
    api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`

    // Fetch any initial data needed
    console.info('Fetching initial data');
    await store.dispatch('enums/fetchAll');

    // Update locale if needed
    locale.value = store.getters['user/settings'].language || import.meta.env.VITE_DEFAULT_LOCALE;

    Sentry.setUser(userData);
  } catch (error) {
    console.error('Error during post-authentication actions:', error);
    await router.replace({ name: 'Login' });
  }
}

async function renewToken() {
  console.log('Renewing token...');

  if (!msal) {
    console.error('MSAL instance is not available');
    return;
  }

  const activeAccount = msal.getActiveAccount();
  if (!activeAccount) {
    console.error('No active account found for token renewal');
    return;
  }

  const msalOptions: SilentRequest = {
    scopes: [`${import.meta.env.VITE_MSAL_CLIENT_ID}/.default`],
    account: activeAccount,
    forceRefresh: true,
  };

  try {
    console.info('Renewing MSAL token...');
    const response = await msal.acquireTokenSilent(msalOptions);
    console.info('Token renewed successfully');
    await authenticated(response.accessToken, activeAccount);
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      console.warn('Silent token renewal failed, requiring interaction:', error);
      try {
        await msal.acquireTokenRedirect(msalOptions);
      } catch (interactiveError) {
        console.error('Interactive authentication failed', interactiveError);
        await router.replace({ name: 'Login' });
      }
    } else {
      console.error('Non-interactive error occurred during token acquisition', error);
      await router.replace({ name: 'Login' });
    }
  }
}

function startTokenRenewalCheck() {
  console.log('Starting token renewal check');
  const renewalPeriod = 15 * 60 * 1000; // Example: renew every 15 minutes
  tokenRenewalInterval.value = setInterval(renewToken, renewalPeriod);
}

function stopTokenRenewalCheck() {
  console.log('Stopping token renewal check');
  if (tokenRenewalInterval.value) clearInterval(tokenRenewalInterval.value);
}

onMounted(async () => {
  console.info('Component mounted, checking authentication');
  await checkAuthentication();
  startTokenRenewalCheck(); // Start token renewal check after authentication
  // await renewToken(); // Renew token on every mount
});

onBeforeUnmount(() => {
  stopTokenRenewalCheck();
});

// State to track whether the menu is visible or hidden
const isMenuVisible = ref(false);

// Refs for button and menu positioning
const actionButtonRef = ref<HTMLElement | null>(null);
const menuRef = ref<HTMLElement | null>(null);

// Function to toggle the menu visibility
const toggleMenu = () => {
  isMenuVisible.value = !isMenuVisible.value;

  // After toggling, calculate and position the menu above the button
  nextTick(() => {
    if (menuRef.value && actionButtonRef.value) {
      const buttonRect = actionButtonRef.value.getBoundingClientRect();
      menuRef.value.style.bottom = `${window.innerHeight - buttonRect.top + 10}px`; // 10px space between button and menu

      // Dynamically adjust the menu position based on button position
      if (buttonRect.left < window.innerWidth / 2) {
        // If button is on the left side of the screen, align menu to the right
        menuRef.value.style.left = `${buttonRect.left}px`;
        menuRef.value.style.right = 'auto';
      } else {
        // If button is on the right side of the screen, align menu to the left
        menuRef.value.style.right = `${window.innerWidth - buttonRect.right}px`;
        menuRef.value.style.left = 'auto';
      }
    }
  });
};

// Function to close the menu if clicked outside
const handleClickOutside = (event: MouseEvent) => {
  const menuEl = menuRef.value;
  const buttonEl = actionButtonRef.value;

  if (
    menuEl && buttonEl &&
    !menuEl.contains(event.target as Node) &&
    !buttonEl.contains(event.target as Node)
  ) {
    isMenuVisible.value = false;
  }
};

// Define the margin to keep the button away from the edge
const marginFromEdge = 10; // Adjust this value to set the distance from the screen edge

onMounted(() => {
  if (actionButtonRef.value) {
    // Set up interact.js draggable for the action button
    interact(actionButtonRef.value).draggable({
      modifiers: [
        interact.modifiers.restrictRect({
          restriction: {
            top: marginFromEdge,
            left: marginFromEdge,
            bottom: window.innerHeight - marginFromEdge,
            right: window.innerWidth - marginFromEdge,
          },
          endOnly: true,
        }),
      ],
      listeners: {
        move(event) {
          const target = event.target as HTMLElement;
          const x = (parseFloat(target.getAttribute('data-x')!) || 0) + event.dx;
          const y = (parseFloat(target.getAttribute('data-y')!) || 0) + event.dy;

          target.style.transform = `translate(${x}px, ${y}px)`;
          target.setAttribute('data-x', x.toString());
          target.setAttribute('data-y', y.toString());

          // Recalculate the menu position after dragging
          nextTick(() => {
            if (menuRef.value && actionButtonRef.value && isMenuVisible.value) {
              const buttonRect = actionButtonRef.value.getBoundingClientRect();
              menuRef.value.style.bottom = `${window.innerHeight - buttonRect.top + 10}px`;

              // Adjust menu position based on button's position
              if (buttonRect.left < window.innerWidth / 2) {
                menuRef.value.style.left = `${buttonRect.left}px`;
                menuRef.value.style.right = 'auto';
              } else {
                menuRef.value.style.right = `${window.innerWidth - buttonRect.right}px`;
                menuRef.value.style.left = 'auto';
              }
            }
          });
        },
      },
      inertia: true,
    });
  }

  // Add event listener to close menu on click outside
  document.addEventListener('click', handleClickOutside);
});

onUnmounted(() => {
  // Remove event listener when component is destroyed
  document.removeEventListener('click', handleClickOutside);
});

// Trail positions
const trailPositions = reactive<{ id: number, x: number, y: number, size: number, opacity: number }[]>([]);
let trailId = 0;

const createTrailEffect = (x: number, y: number) => {
  trailId += 1;
  const size = Math.random() * 10 + 5; // Smaller size for the trail circles
  const newTrail = { id: trailId, x, y, size, opacity: 1 };
  trailPositions.push(newTrail);

  // Remove the trail after a shorter delay
  setTimeout(() => {
    const trailIndex = trailPositions.findIndex(trail => trail.id === newTrail.id);
    if (trailIndex !== -1) {
      trailPositions[trailIndex].opacity = 0;
      setTimeout(() => trailPositions.splice(trailIndex, 1), 300); // Remove from array after fading out faster
    }
  }, 300); // Shorter display duration
};

const onMouseMove = (event: MouseEvent) => {
  const { clientX, clientY } = event;
  createTrailEffect(clientX, clientY);
};

const onTouchMove = (event: TouchEvent) => {
  const touch = event.touches[0];
  createTrailEffect(touch.clientX, touch.clientY);
};

const onMouseDown = (event: MouseEvent) => {
  window.addEventListener('mousemove', onMouseMove);
  window.addEventListener('mouseup', onMouseUp);
};

const onMouseUp = () => {
  window.removeEventListener('mousemove', onMouseMove);
  window.removeEventListener('mouseup', onMouseUp);
};

const onTouchStart = (event: TouchEvent) => {
  window.addEventListener('touchmove', onTouchMove);
  window.addEventListener('touchend', onTouchEnd);
};

const onTouchEnd = () => {
  window.removeEventListener('touchmove', onTouchMove);
  window.removeEventListener('touchend', onTouchEnd);
};
</script>


<template>
   <InteractableTutorial
    @update:expanded="sidebarExpanded = $event; sidebarOpen = $event"
   />
  <!-- Notifications -->
  <div class="fixed inset-0 z-[100] pointer-events-none select-none" aria-hidden="true">
    <div class="absolute inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start">
      <div class="w-full flex flex-col-reverse items-center sm:items-end self-end">
        <div v-for="notification in store.getters['notifications/all']" :key="notification.id" class="max-w-xs mt-3 backdrop-blur-sm bg-white/90 shadow-lg rounded-lg pointer-events-auto ring ring-black ring-opacity-10 overflow-hidden">
          <div class="p-3">
            <div :class="[
              'flex',
              notification.message && notification.message.length ? 'items-start' : 'items-center',
            ]">
              <div class="flex items-center justify-center">
                <i class="fad text-xl fa-check-circle text-green-600" v-if="notification.type === 'success'" />
                <i class="fad text-xl fa-exclamation-triangle text-softred-600" v-else-if="notification.type === 'error'" />
                <i class="fad text-xl fa-info-circle text-primary-600" v-else-if="notification.type === 'info'" />
                <i class="fad text-xl fa-exclamation-circle text-yellow-600" v-else-if="notification.type === 'warning'" />
              </div>
              <div class="ml-3">
                <p class="text-sm font-medium text-gray-900 first-letter:uppercase">
                  {{ notification.title }}
                </p>
                <p class="mt-1 text-sm text-gray-500 first-letter:uppercase" v-html="notification.message" v-if="notification.message && notification.message.length">
                </p>
              </div>
              <div class="ml-4 flex-shrink-0 flex">
                <button
                  @click="store.dispatch('notifications/remove', notification)"
                  type="button"
                  class="inline-flex text-gray-500 focus:outline-none focus:ring-2 focus:ring-white hover:text-black transition-colors">
                  <span class="sr-only" v-t="'Close'"></span>
                  <i class="fad fa-times" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Dialog -->
  <TransitionRoot appear :show="store.getters['dialog/show']" as="template">
    <Dialog as="div" className="relative z-[1000]" @close="store.dispatch('dialog/close')">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95">
            <DialogPanel class="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
              <div class="sm:flex sm:items-start sm:gap-x-4">
                <div
                  v-if="store.getters['dialog/showTypeIcon']"
                  :class="[
                    'mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10',
                    store.getters['dialog/type'] === 'success' ? 'bg-green-100 text-green-600' : '',
                    store.getters['dialog/type'] === 'error' ? 'bg-softred-100 text-softred-600' : '',
                    store.getters['dialog/type'] === 'info' ? 'bg-primary-100 text-primary-600' : '',
                    store.getters['dialog/type'] === 'warning' ? 'bg-yellow-100 text-yellow-600' : '',
                  ]">
                  <i class="far fa-check-circle" v-show="store.getters['dialog/type'] === 'success'" />
                  <i class="far fa-exclamation-triangle" v-show="store.getters['dialog/type'] === 'error'" />
                  <i class="far fa-info-circle" v-show="store.getters['dialog/type'] === 'info'" />
                  <i class="far fa-exclamation-circle" v-show="store.getters['dialog/type'] === 'warning'" />
                </div>

                <div class="mt-3 text-center sm:mt-0 sm:text-left w-full">
                  <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900 first-letter:capitalize">
                    {{ store.getters['dialog/title'] }}
                  </DialogTitle>
                  <div class="mt-2" v-show="store.getters['dialog/message'] && store.getters['dialog/message'].length">
                    <p class="text-sm text-gray-500" v-html="store.getters['dialog/message']"></p>
                  </div>

                  <div class="mt-4 flex flex-row-reverse gap-3" v-show="store.getters['dialog/buttons'] && store.getters['dialog/buttons'].length">
                    <template v-for="button in store.getters['dialog/buttons']">
                      <button
                        type="button"
                        :disabled="button.isLoading"
                        :class="[
                          { 'btn-default': button.type === 'default' },
                          { 'btn-primary': button.type === 'primary' },
                          { 'btn-danger': button.type === 'danger' },
                        ]"
                        @click="store.getters['dialog/buttons'].forEach((b) => b.isLoading = true); button.action()"
                      >
                        <template v-if="button.isLoading">
                          <i class="far fa-spinner-third animate-spin" />
                        </template>
                        <template v-else>
                          {{ button.text }}
                        </template>
                      </button>
                    </template>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

  <!-- Sidebar TIJDELIJK UITGESCHAKELD-->
  <!-- <div class="relative"> -->
    <!-- Action button -->
    <!-- <div
      ref="actionButtonRef"
      @click="toggleMenu"
      @mousedown="onMouseDown"
      @touchstart="onTouchStart"
      class="md:hidden fixed bottom-5 right-5 h-10 w-10 text-center p-2 bg-primary z-[2000] rounded-full text-white shadow-lg cursor-pointer hover:bg-primary-600"
      style="touch-action: none;">
      <i class="fas fa-plus"></i>
    </div> -->

    <!-- Trail Effect -->
    <!-- <div v-for="trail in trailPositions" :key="trail.id"
      class="fixed bg-primary-300 rounded-full z-[2002] opacity-75 pointer-events-none"
      :style="{ width: trail.size + 'px', height: trail.size + 'px', top: trail.y + 'px', left: trail.x + 'px', transform: 'translate(-50%, -50%)', transition: 'opacity 0.3s ease-out', opacity: trail.opacity }">
    </div> -->

    <!-- Menu that appears when the action button is clicked -->
    <!-- <transition name="fade-slide">
      <div
        v-if="isMenuVisible"
        ref="menuRef"
        :class="menuPosition"
        class="fixed bg-white/20 backdrop-blur-sm rounded-lg shadow-md space-y-2 w-40 z-40 transition-all duration-300">
        <button class="btn-primary w-full p-2 rounded-md">
          <router-link :to="{ name: 'control-panel.projects.list' }" v-slot="{ navigate, href }" custom>
            <a
              class="w-full bg-center bg-no-repeat bg-contain"
              :href="href"
              @click="navigate"
            >
              {{ t('Project', 2) }}
            </a>
          </router-link>
        </button>
      </div>
    </transition> -->
  <!-- </div> -->

  <TransitionRoot as="template" :show="sidebarOpen">
    <Dialog as="div" class="relative z-40 md:hidden" @close="sidebarOpen = false">
      <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-600 bg-opacity-75" />
      </TransitionChild>

      <div class="fixed ios-notch-top-padding inset-0 z-40 flex">
        <TransitionChild as="template" enter="transition ease-in-out duration-150 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-150 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
          <DialogPanel class="relative flex w-full max-w-48 flex-1 flex-col bg-white">
            <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button type="button" class="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" @click="sidebarOpen = false">
                  <span class="sr-only">Sluit sidebar</span>
                  <i class="far fa-times text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <div class="h-0 flex-1 overflow-y-auto pt-5 pb-4">
              <div class="flex flex-shrink-0 items-center px-4 font-semibold">
                <router-link :to="{ name: 'control-panel.projects.list' }" v-slot="{ navigate, href }" custom>
                  <a
                    class="w-full bg-center bg-no-repeat bg-contain h-4 block"
                    :href="href"
                    @click="navigate"
                    :style="'background-image:url(' + publicPath + '/img/logo-white.svg)'"
                  />
                </router-link>
              </div>
              <nav class="mt-5 space-y-1 px-2">
                <router-link
                  custom
                  v-for="item in topNavigation"
                  :key="item.name"
                  :to="item.route"
                  v-slot="{ isActive, href, navigate }">
                  <a :href="href" @click="navigate" :class="[isActive ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-2 py-2 text-base font-medium rounded-md']">
                    <i :class="[item.icon, isActive ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500', 'mr-4 flex-shrink-0 w-6 text-center']" aria-hidden="true" />
                    <p>{{ item.name }}</p>
                  </a>
                </router-link>
              </nav>
            </div>
            <div class="flex flex-shrink-0 border-t border-gray-200 p-4">
              <a href="/auth/sign-out" class="flex group items-center gap-2 flex-shrink-0">
                <!-- <p class="text-base font-medium text-gray-800 group-hover:text-gray-900 hidden">Jordy Brouwers</p> -->
                <i class="far fa-sign-out-alt text-gray-500 group-hover:text-gray-800 ml-2" aria-hidden="true" />
                <p class="text-sm font-medium text-gray-500 group-hover:text-gray-800">Uitloggen</p>
              </a>
            </div>
          </DialogPanel>
        </TransitionChild>
        <div class="w-14 flex-shrink-0">
          <!-- Force sidebar to shrink to fit close icon -->
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

  <Sidebar v-model:expanded="sidebarExpanded">
    <template #logo>
      <div class="flex justify-center items-center h-12 bg-white px-3 gap-x-3.5 text-center border-b border-gray-200">
        <RouterLink :to="{ name: 'map.projects' }" class="bg-right bg-[length:198px_27px] bg-no-repeat h-7 w-7 hidden" :style="`background-image:url(${publicPath}/img/logo-white.svg)`"/>
        <RouterLink :to="{ name: 'map.projects' }" class="bg-right bg-[length:198px_27px] bg-no-repeat h-7 w-7 inline-block" :style="`background-image:url(${publicPath}/img/logo-dark.svg)`"/>
      </div>
    </template>

    <template #top>
      <SidebarNav>
        <SidebarItems v-slot="{ item, href, isActive, navigate }" :items="[
          { name: t('Map'), route: { name: 'control-panel.map' }, icon: 'far fa-map-location-dot' },
          { name: t('Project', 2), route: { name: 'control-panel.projects' }, icon: 'fas fa-list' }
        ]">
          <SidebarItem :href="href" :icon="item.icon" :label="item.name" :isActive="isActive" :textHidden="!sidebarExpanded" @navigate="navigate"/>
        </SidebarItems>
      </SidebarNav>
    </template>

    <template #bottom>
      <SidebarNav>
        <SidebarItems v-slot="{ item, href, isActive, navigate }" :items="[
          { name: t('Help'), event: 'startTutorial', disableSmallScreen: true, icon: 'far fa-question-circle' },
          { name: t('Manual', 2), route: { name: 'manuals' }, icon: 'far fa-book' },
          { name: t('Setting', 2), route: { name: 'settings' }, icon: 'far fa-cog' },
          { name: t('Sign out'), route: { name: 'auth.signOut' }, icon: 'far fa-sign-out-alt' },
        ]" >
          <SidebarItem :href="href" :icon="item.icon" :label="item.name" :isActive="isActive" :textHidden="!sidebarExpanded" @navigate="navigate"/>
        </SidebarItems>
      </SidebarNav>
    </template>
  </Sidebar>

  <main class="flex flex-col flex-1 overflow-x-hidden min-h-screen relative bg-slate-75">
    <div class="absolute inset-0 z-50 bg-white pointer-events-none" v-if="!essentialsFetched">
      <div class="absolute inset-0 flex items-center justify-center">
        <div class="flex flex-col items-center space-y-2">
          <i class="far fa-spinner-third animate-spin text-5xl text-black"/>
        </div>
      </div>
    </div>

    <router-view v-if="essentialsFetched"/>
  </main>
</template>

<style>
/* Smooth transition for menu visibility */
.fade-slide-enter-active,
.fade-slide-leave-active {
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}
.fade-slide-enter-from,
.fade-slide-leave-to {
  opacity: 0;
  transform: translateY(20px);
}
.fade-slide-enter-to,
.fade-slide-leave-from {
  opacity: 1;
  transform: translateY(0);
}

.trail {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: rgba(59, 130, 246, 0.5); /* Tailwind's blue-500 with opacity */
  border-radius: 9999px; /* Fully round circle */
  pointer-events: none; /* So the user can't interact with the trail */
  animation: fade 0.5s ease-out forwards; /* Fades the trail */
}

@keyframes fade {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(2);
    opacity: 0;
  }
}
</style>
